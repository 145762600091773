import React, { useEffect, useState } from 'react';
import { AiOutlineEdit, AiOutlineDelete, AiOutlineFileExcel } from 'react-icons/ai';
import { FiPlus } from 'react-icons/fi';
import { useHistory, Link } from 'react-router-dom';
import { Button } from '../../../../../Components/Atoms/Button';
import { ConfirmDeleteProductModal } from './ConfirmDeleteProductModal';
import { useAuth } from '../../../../AuthControl';
import { useTenant } from '../../../../TenantControl';
import { Product } from '../../Controls/DataControl/models';
import style from './ProductsListPage.module.css';
import { Tooltip } from '../../../../../Components/Atoms/Tooltip';
import { useTranslation } from 'react-i18next';

export const ProductsListPage = (): React.ReactElement => {
  const [products, setProducts] = useState<Product[]>([]);
  const { api } = useAuth();
  const { client } = useTenant();
  const { push } = useHistory();
  const [productToDelete, setProductToDelete] = useState<Product>();
  const { t } = useTranslation();

  const fetchProducts = async () => {
    const result = await api.get<Product[]>(
      `/api/client/${client?.tenant}/registrations/products`
    );
    setProducts(result);
  };

  useEffect(() => {
    fetchProducts();
    const pollingProducts = setInterval(() => {
      fetchProducts();
    }, 10000);
    return () => {
      clearInterval(pollingProducts);
    };
  }, []);

  return (
    <div className={style.container}>
      {productToDelete && (
        <ConfirmDeleteProductModal
          tenant={client?.tenant || ''}
          productId={productToDelete.id}
          productName={productToDelete.name}
          handleClose={(success) => {
            setProductToDelete(undefined);
            if (success) {
              fetchProducts();
            }
          }}
        />
      )}
      <Button
        onClick={() => push(`${location.pathname}/novo`)}
        icon={<FiPlus />}
      >
        {t('productPage:product', { defaultValue: 'Produto' })}
      </Button>
      <ul className={`${style.productsList}`}>
        <li className={`${style.header}`} key='header'>
          <div className={`${style.column} ${style.imageColumn}`}></div>
          <div className={`${style.column} ${style.nameColumn}`}>{t('productPage:name', { defaultValue: 'Nome' })}</div>
          <div className={`${style.column} ${style.infoColumn}`}>{t('productPage:codeText', { defaultValue: 'Código Texto' })}</div>
          <div className={`${style.column} ${style.infoColumn}`}>{t('productPage:netWeightUnitName', {unitName: 'g' ,defaultValue: `Peso Líquido` })}</div>
          <div className={`${style.column} ${style.infoColumn}`}>{t('productPage:brand', { defaultValue: 'Marca' })}</div>
          <div className={`${style.column} ${style.actionsColumn}`}></div>
        </li>
        {products.map((product) => (
          <li key={product.id} className={style.row}>
            <div className={`${style.column} ${style.imageColumn}`}>
              <img
                src={product.imagePath ?? '/icone_produto.png'}
                alt={product.name}
                width={36}
              />
            </div>
            <div className={`${style.column} ${style.nameColumn}`}>
              {product.name}
            </div>
            <div className={`${style.column} ${style.infoColumn}`}>
              {product.codeText}
            </div>
            <div className={`${style.column} ${style.infoColumn}`}>
              {product.netWeight}
            </div>
            <div className={`${style.column} ${style.infoColumn}`}>
              {product.brand}
            </div>
            <div className={`${style.column} ${style.actionsColumn}`}>
              <Tooltip tooltip={t('productPage:productConfiguration', { defaultValue: 'Configurações do Produto' })}>
                <Link
                  to={`${location.pathname}/${encodeURI(
                    product.id.toString()
                  )}/config`}
                >
                  <AiOutlineFileExcel onClick={() => localStorage.setItem('@medianeira:selectedproduct', JSON.stringify(product))} />
                </Link>
              </Tooltip>
              <Tooltip tooltip={t('productPage:edit', { defaultValue: 'Editar' })}>
                <Link
                  to={`${location.pathname}/${encodeURI(
                    product.codeText || product.code.toString()
                  )}`}
                >
                  <AiOutlineEdit />
                </Link>
              </Tooltip>
              <Tooltip tooltip={t('productPage:delete', { defaultValue: 'Excluir' })}>
                <div>
                  <AiOutlineDelete
                    onClick={() => setProductToDelete(product)}
                  />
                </div>
              </Tooltip>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
