import { useTranslation } from "react-i18next";
import { Input } from "../../../../../Components/Atoms/Input";
import { useEffect, useState } from "react";
import { Select } from "../../../../../Components/Atoms/Input/Select";
import { EquipmentNameModel } from "./models";
import { Button } from "../../../../../Components/Atoms/Button";
import { useToast } from "../../../../../hooks/toast";
import { useAuth } from "../../../../AuthControl";
import { useHistory } from "react-router-dom";
import { useTenant } from "../../../../TenantControl";

export const EquipmentGroupCreationPage = (): React.ReactElement => {
  const { t } = useTranslation();
  const [groupName, setGroupName] = useState<string>();
  const [availableEquipments, setAvailableEquipments] = useState<EquipmentNameModel[]>([]);
  const [equipmentToAdd, setEquipmentToAdd] = useState<EquipmentNameModel>();
  const { api, user } = useAuth();
  const { addToast } = useToast();
  const { client } = useTenant();
  const { push } = useHistory();

  useEffect(() => {
    api.get<EquipmentNameModel[]>(`/api/client/${user?.tenant}/registrations/equipments/groups/available`)
      .then((response) => setAvailableEquipments(response));
  }, []);

  const handleAddEquipmentToGroup = async () => {
    if (!equipmentToAdd) {
      addToast({ title: t('equipmentPage:selectEquipment', { defaultValue: 'Selecione um equipamento.' }), type: 'info' });
      return;
    }

    try {
      await api.post(`/api/client/${user?.tenant}/registrations/equipments/groups/${groupName}`, { equipments: equipmentToAdd?.id })
        .then(() => {
          push(`/client/${client?.tenant}/admin/grupos`);
        });
        addToast({ title: t('equipmentPage:equipmentAddedSuccessfully', { defaultValue: 'Grupo criado com sucesso.' }), type: 'success' });
    } catch (err) {
      addToast({ title: t('equipmentPage:errorAddingEquipmentToGroup', { defaultValue: 'Erro ao adicionar equipamento ao grupo.' }), type: 'error' });
    }
  }

  return (
    <div style={{
      maxWidth: '900px',
      margin: '0 auto',
      padding: '1rem 0'
    }}>
      <div>
        <div
          style={{
            margin: '20px',
            fontSize: '20px',
            fontWeight: 'bolder'
          }}>
          {t('equipmentPage:enterTheGroupName', { defaultValue: 'Digite o nome do Grupo.' })}
        </div>
        <div style={{
          margin: '20px'
        }}>
          <Input
            id='groupName'
            grow
            label={t('equipmentPage:groupName', { defaultValue: 'Nome do Grupo' })}
            type='text'
            value={groupName}
            onChange={setGroupName}
          />
        </div>
      </div>
      <>
        <div style={{ margin: '20px' }}>
          <Select
            id='add'
            label={t('equipmentPage:selectOneEquipment', { defaultValue: 'Selecione um equipamento' })}
            options={availableEquipments.map((equip) => equip.name)}
            value={equipmentToAdd ? equipmentToAdd.name : ''}
            onChange={(value) => {
              const selectedEquipment = availableEquipments.find((equip) => equip.name === value);
              setEquipmentToAdd((_) => selectedEquipment);
            }}
          />
        </div>
        <div style={{ margin: '20px' }}>
          <Button
            onClick={handleAddEquipmentToGroup}>
            {t('equipmentPage:confirm', { defaultValue: 'Confirmar' })}
          </Button>
        </div>
      </>
      <div style={{
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'flex-end'
      }}>
        <Button
          secondary
          danger
          onClick={() => push(`/client/${client?.tenant}/admin/grupos`)}>
          {t('equipmentPage:return', { defaultValue: 'Voltar' })}
        </Button>
      </div>
    </div>
  );
}