import { useEffect, useState } from 'react';
import { Button } from '../../../../../Components/Atoms/Button';
import Checkbox from '../../../../../Components/Atoms/Checkbox';
import Modal from '../../../../../Components/Modal';
import { useTranslation } from 'react-i18next';
import { getWeightOverTimeBlacklist, saveWeightOverTimeBlacklist } from './WeightOverTimeFilterService';
import style from './WeightOverTimeFilterModal.module.css';

interface Props {
  handleClose: () => void;
}

interface TypeDto {
  code: string;
  name: string;
}

export const WeightOverTimeFilterModal = ({ handleClose }: Props) => {
  const [weightOverTimeBlacklist, setweightOverTimeBlacklist] = useState<string[]>([]);
  const [types, setTypes] = useState<TypeDto[]>([]);
  const { t } = useTranslation();

  const handleCloseModal = () => {
    handleClose();
  };

  const fetchTypes = () => {
    setTypes([
      {
        code: "useful",
        name: t('specificChartPage:useful', { defaultValue: 'Úteis' })
      },
      {
        code: "rejected",
        name: t('specificChartPage:rejectedPlural', { defaultValue: 'Rejeitados' })
      },
      {
        code: "invalid",
        name: t('specificChartPage:invalids', { defaultValue: 'Inválidos' })
      }
    ]);
  };

  useEffect(() => {
    setweightOverTimeBlacklist(getWeightOverTimeBlacklist());
    fetchTypes();
  }, []);

  return (
    <>
      <Modal handleClose={handleCloseModal}>
        <div className={style.container}>
          <div className={style.header}>
            <h3>{t('specificChartPage:filterWeightXTime', { defaultValue: 'Filtrar Peso x Tempo' })}</h3>
            <div className={style.bulkSelection}>
              <Button
                textual
                danger
                compact
                onClick={() =>
                  setweightOverTimeBlacklist(
                    types.map(({ code }) => code.toString())
                  )}>
                {t('specificChartPage:clear', { defaultValue: 'Limpar' })}
              </Button>
              <Button textual compact onClick={() => setweightOverTimeBlacklist([])}>
                {t('specificChartPage:selectAll', { defaultValue: 'Selecionar todos' })}
              </Button>
            </div>
          </div>
          <div className={style.list}>
            {types
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(({ code, name }) => (
                <Checkbox
                  label={name}
                  inline
                  tiny
                  checked={!weightOverTimeBlacklist.includes(code.toString())}
                  onChange={(checked) => {
                    if (checked)
                      setweightOverTimeBlacklist((prev) =>
                        prev.filter((item) => item !== code.toString())
                      );
                    else
                      setweightOverTimeBlacklist((prev) => [
                        ...prev,
                        code.toString(),
                      ]);
                  }}
                />
              ))}
          </div>
          <div className={style.actions}>
            <Button
              danger
              onClick={() => {
                handleClose();
              }}>
              {t('specificChartPage:cancel', { defaultValue: 'Cancelar' })}
            </Button>
            <Button
              onClick={() => {
                saveWeightOverTimeBlacklist(weightOverTimeBlacklist);
                handleClose();
              }}>
              {t('specificChartPage:save', { defaultValue: 'Salvar' })}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}