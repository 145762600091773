import React, { useEffect, useState } from 'react';
import { useAuth, User } from '../../../../../Client/AuthControl';
import { useToast } from '../../../../../hooks/toast';
import { useParams } from 'react-router-dom';
import { Button } from '../../../../../Components/Atoms/Button';
import { Input } from '../../../../../Components/Atoms/Input';
import { Select } from '../../../../../Components/Atoms/Input/Select';
import style from './UserPage.module.css';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  newUser?: boolean;
}

export const UserPage = ({
  newUser,
}: {
  newUser?: boolean;
}): React.ReactElement => {
  const { push, location, goBack } = useHistory();
  const { api, user } = useAuth();
  const { addToast } = useToast();
  const { id } = useParams<{ id: string }>();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const { t } = useTranslation();

  const mapRole = (role: string): string | undefined =>
  ({
    Admin: t('userPage:administrator', { defaultValue: 'Administrador' }),
    Operator: t('userPage:operator', { defaultValue: 'Operador' }),
    Supervisor: t('userPage:supervisor', { defaultValue: 'Supervisor' }),
  }[role]);
  const mapRoleReverse = (role: string): string | undefined =>
  ({
    Administrador: t('userPage:admin', { defaultValue: 'Admin' }),
    Operador: t('userPage:operator', { defaultValue: 'Operador' }),
    Supervisor: t('userPage:supervisor', { defaultValue: 'Supervisor' }),
  }[role]);

  useEffect(() => {
    if (!newUser) {
      api
        .get<User>(`/api/client/${user?.tenant}/users/profile/${id}`)
        .then((response) => {
          setName(response.name);
          setEmail(response.email);
          setRole(mapRole(response.role) ?? '');
        });
    }
  }, [newUser, id]);

  const handleSubmitCreate = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await api.post(`/api/client/${user?.tenant}/users/profile`, {
        name,
        email,
        role: mapRoleReverse(role),
      });
      addToast({
        title: t('userPage:userCreated', { defaultValue: 'Usuário criado' }),
        description: t('userPage:userCreatedDescription', { defaultValue: 'Um email foi encaminhado para o usuário finalizar seu cadastro.' }),
        type: 'info',
      });
      push(`/client/${user?.tenant}/admin/usuarios`);
    } catch (exception) {
      console.error(exception);
      addToast({
        title: t('userPage:error', { defaultValue: 'Erro' }),
        description: t('userPage:problemAddingUser', { defaultValue: 'Problema ao adicionar usuário.' }),
        type: 'error',
      });
    }
  };

  const handleSubmitUpdate = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <form
      className={style.userForm}
      onSubmit={(e) => {
        newUser ? handleSubmitCreate(e) : handleSubmitUpdate(e);
      }}
    >
      <h1>{newUser ? t('userPage:addNewUser', { defaultValue: 'Adicionar Novo Usuário' }) :
        t('userPage:editUserName', { userName: name, defaultValue: `Editar ${name}` })}
      </h1>
      <Input
        disabled={!newUser}
        id='email'
        grow
        label={t('userPage:email', { defaultValue: 'Email' })}
        type='text'
        value={email}
        onChange={setEmail}
      />
      <Input
        id='name'
        grow
        label={t('userPage:name', { defaultValue: 'Nome' })}
        type='text'
        value={name}
        onChange={setName}
      />
      <Select
        options={
          [t('userPage:administrator', { defaultValue: 'Administrador' }),
          t('userPage:operator', { defaultValue: 'Operador' }),
          t('userPage:supervisor', { defaultValue: 'Supervisor' })]
        }
        id='role'
        grow
        label={t('userPage:role', { defaultValue: 'Permissão' })}
        type='text'
        value={role}
        onChange={(value) => {
          setRole(value);
        }}
      />
      <div className={style.controlButtons}>
        <Button secondary danger key='cancel' onClick={() => goBack()}>
          {t('userPage:cancel', { defaultValue: 'Cancelar' })}
        </Button>
        <Button type='submit' key='save'>
          {t('userPage:save', { defaultValue: 'Salvar' })}
        </Button>
      </div>
    </form>
  );
};
