import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import style from './ProductionOrdersPage.module.css';
import { useTranslation } from 'react-i18next';
import { useData } from '../../Controls/DataControl/UseData';
import { ProductionOrder } from '../../Controls/DataControl/models';
import Chart from 'react-apexcharts';
import { EmptyState } from '../../Components/Cards/EmptyState';
import { SpecificChartPageTitle } from '../../Components/SpecificChartPageTitle';
import { ApexOptions } from 'apexcharts';
import { formatNumberToNotation } from '../../../../../helper/FormatNumberToNotation';
import { ConvertValueToBestUnit } from '../../../../../helper/ConvertValueToBestUnit';
import { useFilter } from '../../Controls/FilterControl';
import { AxisTitleConfiguration } from '../../../../../helper/AxisTitleConfiguration';
import { LoadingState } from '../../Components/Cards/LoadingState';
import { Filter } from '../../Controls/FilterControl/models';

function timeSince(timestamp: number) {

  const msInSecond = 1000;
  const msInMinute = msInSecond * 60;
  const msInHour = msInMinute * 60;
  const msInDay = msInHour * 24;
  const msInMonth = msInDay * 30;

  const months = Math.floor(timestamp / msInMonth);
  const days = Math.floor((timestamp % msInMonth) / msInDay);
  const hours = Math.floor((timestamp % msInDay) / msInHour);
  const minutes = Math.floor((timestamp % msInHour) / msInMinute);
  const seconds = Math.floor((timestamp % msInMinute) / msInSecond);

  return { months, days, hours, minutes, seconds };
}

export const ProductionOrdersPage = ({
  containerHeight, containerWidth
}: {
  containerHeight: number,
  containerWidth: number
}): React.ReactElement => {
  const { t } = useTranslation();
  const { filter, resetFilterRules } = useFilter();
  const { fetchProductionOrders } = useData();
  const [loading, setLoading] = useState<boolean>(true);
  const [series, setSeries] = useState<any>([]);
  const [options, setOptions] = useState<ApexOptions>({});
  const [productionOrders, setProductionOrders] = useState<any[]>([]);
  const abortFetchRef = useRef<() => void>();
  const chartHeight = useMemo(() => containerHeight - 430, [containerHeight]);

  const localFetch = useCallback(
    async (filter: Filter) => {
      setLoading(true);
      try {
        if (abortFetchRef.current) {
          abortFetchRef.current();
          abortFetchRef.current = undefined;
        }

        const { abort, fetch } = fetchProductionOrders();
        abortFetchRef.current = abort;
        await fetch(filter).then((res) => {
          setProductionOrders(res);
          setSeries(
            [{
              data: res.map(({ id, producedPackages, totalCount }) => {
                return {
                  x: id,
                  y: producedPackages,
                  goals: [
                    {
                      name: 'Número de fardos desejado',
                      value: totalCount,
                      strokeColor: '#36AE81',
                      strokeLineCap: 'round',
                      strokeWidth: 10,
                      strokeHeight: 4,
                    }
                  ]
                }
              })
            }]
          );

          setLoading(false);
        })
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
      setLoading(false);
    },
    [abortFetchRef, fetchProductionOrders, filter]
  );

  useEffect(() => {
    resetFilterRules();
  }, []);

  useEffect(() => {
    localFetch(filter);
  }, [filter]);

  useEffect(() => {
    if (productionOrders.length === 0) return;
    setOptions(
      {
        responsive: [
          {
            breakpoint: 1400,
            options: {
              xaxis: {
                tickAmount: 2,
              },
            },
          },
        ],
        //...ChartOptionsLegend,
        chart: {
          type: 'bar',
          toolbar: { show: false },
          animations: {
            enabled: false,
          },
        },
        xaxis: {
          type: 'category',
          tickPlacement: 'on',
          tickAmount: 4,
          title: { ...AxisTitleConfiguration(t('orderPage:producedPackages', { defaultValue: 'Quantidade Produzida' })), offsetY: 10 },
        },
        yaxis: {
          show: false,
          max: Math.max(...productionOrders.map((order) => order.totalCount)),
          labels: {
            formatter: formatNumberToNotation,
          },
        },
        dataLabels: {
          enabled: true,
          style: { fontSize: '0.875em', colors: ['#222'] },
          textAnchor: 'start',
          offsetX: 4,

          formatter: function (val: any, opt: any) {
            const convertedValue = ConvertValueToBestUnit(
              parseFloat(val.toString())
            );
            const dataPointIndex = opt.dataPointIndex; // Declare the 'dataPointIndex' variable

            const goals =
              opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                .goals

            return `${opt.w.globals.labels[dataPointIndex]}: ${convertedValue.val} / ${goals[0].value}`;
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            padding: 4,
            borderColor: 'transparent',
            opacity: 0.5,
            dropShadow: {
              enabled: false,
            },
          },
        },
        colors: ['#0074D9'],
        legend: {
          show: true,
          showForSingleSeries: true,
          customLegendItems: [
            t('orderPage:projectedPackages', { defaultValue: 'Número de fardos desejado' }),
            t('orderPage:producedPackages', { defaultValue: 'Quantidade de fardos produzida' })
          ],
          markers: {
            fillColors: ['#36AE81', '#0074D9'],
          },
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: true,
            borderRadius: 4,
            dataLabels: {
              position: 'bottom',
              orientation: 'horizontal',
              hideOverflowingLabels: false,
            },
          },
        },
      }
    );
  }, [productionOrders]);

  return (
    <div className={style.container}>
      <SpecificChartPageTitle text={t('specificChartPage:orders', { defaultValue: 'Ordens de Produção' })} />
      {loading ? (
        <LoadingState />
      ) : (
        <>
          <div className={style.tableSection} >
            <table className={style.tableContainer}>
              <thead>
                <tr key="header">
                  <th className={style.headerTitle}>{t('orderPage:id', { defaultValue: 'Id da ordem' })}</th>
                  <th className={style.headerTitle}>{t('orderPage:productNameId', { defaultValue: 'Identificador do produto' })}</th>
                  <th className={style.headerTitle}>{t('orderPage:groupName', { defaultValue: 'Grupo' })}</th>
                  <th className={style.headerTitle}>{t('orderPage:projectedPackages', { defaultValue: 'Número de fardos desejado' })}</th>
                  <th className={style.headerTitle}>{t('orderPage:producedPackages', { defaultValue: 'Quantidade de fardos produzida' })}</th>
                  <th className={style.headerTitle}>{t('orderPage:percentageProgress', { defaultValue: 'Percentual concluído' })}</th>
                  <th className={style.headerTitle}>{t('orderPage:status', { defaultValue: 'Status' })}</th>
                  <th className={style.headerTitle}>{t('orderPage:timeElapsed', { defaultValue: 'Tempo decorrido' })}</th>
                </tr>
              </thead>
              <tbody>
                {productionOrders.map((order) => {
                  const elapsedTime = timeSince(Date.now() - new Date(order.start).getTime());
                  const formattedElapsedTime = `${elapsedTime.months + "m" ?? ""}, ${elapsedTime.days + "d" ?? ""}, ${elapsedTime.hours}:${elapsedTime.minutes}:${elapsedTime.seconds}`;
                  return (<tr key={order.id}>
                    <td>{order.id}</td>
                    <td>{order.product}</td>
                    <td>{order.group ?? t('headerPage:groupless', { defaultValue: 'Sem Grupo' })}</td>
                    <td>{order.totalCount}</td>
                    <td>{order.producedPackages}</td>
                    <td>{(order.producedPackages / order.totalCount * 100).toFixed(2) + "%"}</td>
                    <td>{order.status}</td>
                    <td>{formattedElapsedTime}</td>
                  </tr>)
                })}
              </tbody>
            </table>
          </div>
          <div className={style.chartSection}>
            {options && series.length > 0 ?
              <div className={style.chart}>
                <Chart
                  type="bar"
                  id="opdatachart"
                  height={chartHeight}
                  series={series}
                  options={options}
                />
              </div>
              : <EmptyState />
            }
          </div>
        </>
      )}
    </div>
  )
}
