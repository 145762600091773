import React from 'react';
import { AdvancedFilter } from '../Components/AdvancedFilter';
import { useResponsiveContainer } from '../Controls/ResponsiveContainerControl/UserResponsiveContainer';
import { ProductionClassificationPage } from './ProductionClassificationPage';
import { ProductionConformityPage } from './ProductionConformityPage';
import { ProductionHistogramPage } from './ProductionHistogramPage';
import { ProductionOverTimePage } from './ProductionOverTimePage';
import { ScrapsPage } from './ScrapsPage';
import { OperationalFactorPage } from './OperationalfactorPage';
import { OccurrencesHistogramPage } from './OccurrencesHistogramPage';
import { OccurrencesOverTimePage } from './OccurrencesOverTimePage';
import { CoilConsumptionPage } from './CoilConsumptionPage';
import { PackagesPage } from './PackagesPage';
import { ProductionOrdersPage } from './ProductionOrdersPage';

export const SpecificChartPage = ({
  chartName,
}: {
  chartName: string;
}): React.ReactElement => {
  const { containerHeight, containerWidth } = useResponsiveContainer();

  const Content = {
    fardos: (
      <PackagesPage
      containerWidth={containerWidth}
      containerHeight={containerHeight}
      />
    ),
    classificacao: (
      <ProductionClassificationPage
        containerWidth={containerWidth}
        containerHeight={containerHeight}
      />
    ),
    bobinas: (
      <CoilConsumptionPage
        containerWidth={containerWidth}
        containerHeight={containerHeight}
      />
    ),
    'peso-tempo': (
      <ProductionOverTimePage
        containerHeight={containerHeight}
        containerWidth={containerWidth}
      />
    ),
    'ordens-producao': (
      <ProductionOrdersPage
        containerHeight={containerHeight}
        containerWidth={containerWidth}
      />
    ),
    'histograma-producao': (
      <ProductionHistogramPage
        containerHeight={containerHeight}
        containerWidth={containerWidth}
      />
    ),
    conformidade: (
      <ProductionConformityPage
        containerHeight={containerHeight}
        containerWidth={containerWidth}
      />
    ),
    sobras: (
      <ScrapsPage
        containerHeight={containerHeight}
        containerWidth={containerWidth}
      />
    ),
    'fator-operacional': (
      <OperationalFactorPage
        containerHeight={containerHeight}
        containerWidth={containerWidth}
      />
    ),
    'histograma-ocorrencias': (
      <OccurrencesHistogramPage
        containerHeight={containerHeight}
        containerWidth={containerWidth}
      />
    ),
    ocorrencias: (
      <OccurrencesOverTimePage
        containerHeight={containerHeight}
        containerWidth={containerWidth}
      />
    ),
  }[chartName];
  return (
    <div>
      <AdvancedFilter />
      {Content}
    </div>
  );
};
