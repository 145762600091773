import React, { useEffect, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from '../../../../../Components/Atoms/Button';
import { ConfirmDeleteProductModal } from './ConfirmDeleteProductModal';
import { useAuth } from '../../../../AuthControl';
import { useTenant } from '../../../../TenantControl';
import { Product } from '../../Controls/DataControl/models';
import style from './ProductPage.module.css';
import { Input } from '../../../../../Components/Atoms/Input';
import AppError from '../../../../../helper/AppError';
import { ResizeImageFile } from '../../../../../helper/UploadImageFile';
import { useToast } from '../../../../../hooks/toast';
import { Select } from '../../../../../Components/Atoms/Input/Select';
import Checkbox from '../../../../../Components/Atoms/Checkbox';
import { AvailableUnits } from './AvailableUnits';
import { ErrorCodes } from '../../../../../helper/ErrorCodes';
import { useTranslation } from 'react-i18next';
import { ConfirmDeleteAllProductConfigModal } from './ConfirmDeleteAllProductConfigModal';

interface Props {
  newProduct?: boolean;
}

export const ProductPage = ({ newProduct }: Props): React.ReactElement => {
  const [code, setCode] = useState<number>();
  const [codeText, setCodeText] = useState<string>('');
  const [name, setName] = useState('');
  const [brand, setBrand] = useState('');
  const [barCode, setBarCode] = useState('');
  const [type, setType] = useState('');
  const [netWeight, setNetWeight] = useState<number>();
  const [packageWeight, setPackageWeight] = useState<number>();
  const [packSize, setPackSize] = useState<number>();
  const [correctionBelow, setCorrectionBelow] = useState<number>();
  const [correctionAbove, setCorrectionAbove] = useState<number>();
  const [rejectUnder, setRejectUnder] = useState<number>();
  const [rejectOver, setRejectOver] = useState<number>();
  const [productId, setProductId] = useState<string>();
  const [waitDeleteConfirmation, setWaitDeleteConfirmation] = useState(false);
  const [imagePath, setImagePath] = useState('');
  const [useFactoryDefault, setUseFactoryDefault] = useState(true);
  const [invalidWeightAbove, setInvalidWeightAbove] = useState(0);
  const [invalidWeightBelow, setInvalidWeightBelow] = useState(0);
  const [invalidWeightWhenDevianceAbove, setInvalidWeightWhenDevianceAbove] = useState(0);
  const [unit, setUnit] = useState<{
    text: string;
    unitName: string;
    resolution: number;
  }>({ unitName: 'g', resolution: 0, text: 'grama (res. 1g)' });
  const [image, setImage] = useState<Blob>();
  const { api } = useAuth();
  const { client } = useTenant();
  const { addToast } = useToast();
  const {
    params: { id: editCode },
  } = useRouteMatch<{ id: string }>();
  const { push } = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (newProduct) return;
    api
      .get<Product>(
        `/api/client/${client?.tenant}/registrations/products/${editCode}`
      )
      .then((product) => {
        const localUnit = AvailableUnits[product.unit.index];
        setName(product.name);
        setCode(product.code);
        setCodeText(product.codeText);
        setProductId(product.id);
        setBarCode(product.barCode);
        setImagePath(product.imagePath);
        setUnit(localUnit);
        product.factoryDefault != undefined &&
          setUseFactoryDefault(product.factoryDefault);
        product.packSize && setPackSize(product.packSize);
        product.type && setType(product.type);
        product.brand && setBrand(product.brand);
        product.netWeight &&
          setNetWeight(product.netWeight / Math.pow(10, localUnit.resolution));
        product.packageWeight &&
          setPackageWeight(
            product.packageWeight / Math.pow(10, localUnit.resolution)
          );
        product.correctionBelow &&
          setCorrectionBelow(
            product.correctionBelow / Math.pow(10, localUnit.resolution)
          );
        product.correctionAbove &&
          setCorrectionAbove(
            product.correctionAbove / Math.pow(10, localUnit.resolution)
          );
        product.rejectUnder &&
          setRejectUnder(
            product.rejectUnder / Math.pow(10, localUnit.resolution)
          );
        product.rejectOver &&
          setRejectOver(
            product.rejectOver / Math.pow(10, localUnit.resolution)
          );
        product.invalidAbove &&
          setInvalidWeightAbove(
            product.invalidAbove / Math.pow(10, localUnit.resolution)
          );
        product.invalidBelow &&
          setInvalidWeightBelow(
            product.invalidBelow / Math.pow(10, localUnit.resolution)
          );
        product.invalidDevianceAbove &&
          setInvalidWeightWhenDevianceAbove(
            product.invalidDevianceAbove / Math.pow(10, localUnit.resolution)
          );
      })
      .catch((error: AppError) => {
        addToast({
          title: t('productPage:problemsReadingProduct', { defaultValue: 'Problemas ao ler produto' }),
          description:
            error.statusCode === 404
              ? t('productPage:productNotFound', { defaultValue: 'Produto não encontrado.' })
              : t('productPage:unknownError', { defaultValue: 'Erro desconhecido.' }),
          type: 'error',
        });
        push(`/client/${client?.tenant}/admin/produtos`);
      });
  }, [newProduct]);

  useEffect(() => {
    if (useFactoryDefault) {
      const unitIndex = AvailableUnits.findIndex(
        ({ unitName, resolution }) =>
          unit?.unitName === unitName && resolution === unit.resolution
      );
      const dec = {
        0: 1,
        1: 10,
        2: 100,
        3: 1000,
        4: 10,
        5: 100,
        6: 10,
        7: 100,
        8: 1000,
      }[unitIndex];
      setInvalidWeightAbove(
        parseFloat(
          (
            (((netWeight ?? 0) + (packageWeight ?? 0)) * 1.1 * (dec ?? 0)) /
            Math.pow(10, unit?.resolution ?? 0)
          ).toFixed(unit?.resolution ?? 0)
        )
      );
      setInvalidWeightBelow(
        parseFloat(
          (
            (((netWeight ?? 0) + (packageWeight ?? 0)) * 0.9 * (dec ?? 0)) /
            Math.pow(10, unit?.resolution ?? 0)
          ).toFixed(unit?.resolution ?? 0)
        )
      );
      setInvalidWeightWhenDevianceAbove(
        parseFloat(
          (
            ({ 0: 9, 1: 90, 2: 450, 3: 45, 4: 9, 6: 20, 7: 20, 8: 20 }[
              unitIndex
            ] ?? 0) / Math.pow(10, unit?.resolution ?? 0)
          ).toFixed(unit?.resolution ?? 0)
        )
      );
    }
  }, [useFactoryDefault, netWeight, packageWeight, unit]);

  const handleCreateProduct = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('code', code ? code.toString() : '0');
    formData.append('codeText', codeText);
    formData.append('brand', brand);
    formData.append('barCode', barCode);
    formData.append('type', type);
    formData.append('packSize', packSize ? packSize.toString() : '-1');
    formData.append('unit', (unit?.unitName ?? '').toString());
    formData.append('resolution', (unit?.resolution ?? -1).toString());
    formData.append(
      'unitIndex',
      AvailableUnits.findIndex(
        ({ unitName, resolution }) =>
          unit?.unitName === unitName && resolution === unit.resolution
      ).toString()
    );
    formData.append('factoryDefault', useFactoryDefault ? 'true' : 'false');
    formData.append(
      'netWeight',
      netWeight
        ? (netWeight * Math.pow(10, unit?.resolution ?? 0)).toFixed(0)
        : '-1'
    );
    formData.append(
      'packageWeight',
      packageWeight
        ? (packageWeight * Math.pow(10, unit?.resolution ?? 0)).toFixed(0)
        : '-1'
    );
    formData.append(
      'correctionBelow',
      correctionBelow
        ? (correctionBelow * Math.pow(10, unit?.resolution ?? 0)).toFixed(0)
        : '-1'
    );
    formData.append(
      'correctionAbove',
      correctionAbove
        ? (correctionAbove * Math.pow(10, unit?.resolution ?? 0)).toFixed(0)
        : '-1'
    );
    formData.append(
      'rejectUnder',
      rejectUnder
        ? (rejectUnder * Math.pow(10, unit?.resolution ?? 0)).toFixed(0)
        : '-1'
    );
    formData.append(
      'rejectOver',
      rejectOver
        ? (rejectOver * Math.pow(10, unit?.resolution ?? 0)).toFixed(0)
        : '-1'
    );

    formData.append(
      'invalidBelow',
      (
        (invalidWeightBelow * Math.pow(10, unit?.resolution ?? 0)).toFixed(0) ??
        Number.NEGATIVE_INFINITY
      ).toString()
    );
    formData.append(
      'invalidAbove',
      (
        (invalidWeightAbove * Math.pow(10, unit?.resolution ?? 0)).toFixed(0) ??
        Number.POSITIVE_INFINITY
      ).toString()
    );
    formData.append(
      'invalidDevianceAbove',
      (
        (
          invalidWeightWhenDevianceAbove * Math.pow(10, unit?.resolution ?? 0)
        ).toFixed(0) ?? Number.POSITIVE_INFINITY
      ).toString()
    );
    if (image) {
      formData.append('thumbImage', image, `${code}.jpg`);
      formData.append('filename', `${code}.jpg`);
    }
    try {
      await api.postFormData(
        `/api/client/${client?.tenant}/registrations/products`,
        formData
      );
      addToast({ title: t('productPage:productSuccessfullyCreated', { defaultValue: 'Produto criado com sucesso.' }), type: 'success' });
      push(`/client/${client?.tenant}/admin/produtos`);
    } catch (e) {
      if (e instanceof AppError && e.code === ErrorCodes.ProductAlreadyExists) {
        addToast({ title: t('productPage:productCodeAlreadyInUse', { defaultValue: 'Código do produto já está em uso.' }), type: 'error' });
      } else {
        addToast({ title: t('productPage:problemsAddingProduct', { defaultValue: 'Problema ao adicionar produto.' }), type: 'error' });
      }
    }
  };

  const handleUpdateProduct = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!productId) {
      return;
    }
    const formData = new FormData();
    formData.append('id', productId);
    formData.append('name', name);
    formData.append('barCode', barCode);
    formData.append('code', code ? code.toString() : "0");
    formData.append('codeText', codeText);
    formData.append('brand', brand);
    formData.append('type', type);
    formData.append('unit', (unit?.unitName ?? '').toString());
    formData.append('resolution', (unit?.resolution ?? -1).toString());
    formData.append(
      'unitIndex',
      AvailableUnits.findIndex(
        ({ unitName, resolution }) =>
          unit?.unitName === unitName && resolution === unit.resolution
      ).toString()
    );
    formData.append(
      'netWeight',
      netWeight
        ? (netWeight * Math.pow(10, unit?.resolution ?? 0)).toFixed(0)
        : '-1'
    );
    formData.append(
      'packageWeight',
      packageWeight
        ? (packageWeight * Math.pow(10, unit?.resolution ?? 0)).toFixed(0)
        : '-1'
    );
    formData.append(
      'packSize',
      packSize
        ? (packSize * Math.pow(10, unit?.resolution ?? 0)).toFixed(0)
        : '-1'
    );
    formData.append(
      'correctionBelow',
      correctionBelow
        ? (correctionBelow * Math.pow(10, unit?.resolution ?? 0)).toFixed(0)
        : '-1'
    );
    formData.append(
      'correctionAbove',
      correctionAbove
        ? (correctionAbove * Math.pow(10, unit?.resolution ?? 0)).toFixed(0)
        : '-1'
    );
    formData.append(
      'rejectUnder',
      rejectUnder
        ? (rejectUnder * Math.pow(10, unit?.resolution ?? 0)).toFixed(0)
        : '-1'
    );
    formData.append(
      'rejectOver',
      rejectOver
        ? (rejectOver * Math.pow(10, unit?.resolution ?? 0)).toFixed(0)
        : '-1'
    );

    formData.append('factoryDefault', useFactoryDefault ? 'true' : 'false');

    formData.append(
      'invalidBelow',
      (
        (invalidWeightBelow * Math.pow(10, unit?.resolution ?? 0)).toFixed(0) ??
        Number.NEGATIVE_INFINITY
      ).toString()
    );
    formData.append(
      'invalidAbove',
      (
        (invalidWeightAbove * Math.pow(10, unit?.resolution ?? 0)).toFixed(0) ??
        Number.POSITIVE_INFINITY
      ).toString()
    );
    formData.append(
      'invalidDevianceAbove',
      (
        (
          invalidWeightWhenDevianceAbove * Math.pow(10, unit?.resolution ?? 0)
        ).toFixed(0) ?? Number.POSITIVE_INFINITY
      ).toString()
    );
    if (imagePath) {
      formData.append('imagePath', imagePath);
    }
    if (image) {
      formData.append('thumbImage', image, `${code}.jpg`);
      formData.append('filename', `${code}.jpg`);
    }
    try {
      await api.putFormData(
        `/api/client/${client?.tenant}/registrations/products`,
        formData
      );
      addToast({ title: t('productPage:productSuccessfullyUpdated', { defaultValue: 'Produto atualizado com sucesso.' }), type: 'success' });
      push(`/client/${client?.tenant}/admin/produtos`);
    } catch (e) {
      addToast({ title: t('productPage:problemsUpdatingProduct', { defaultValue: 'Problema ao atualizar produto.' }), type: 'error' });
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      ResizeImageFile(e.target.files[0], 120, 120).then((pic) => setImage(pic));
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={(e) =>
        newProduct ? handleCreateProduct(e) : handleUpdateProduct(e)
      }
    >
      {waitDeleteConfirmation && productId && (
        <ConfirmDeleteProductModal
          handleClose={(success) => {
            setWaitDeleteConfirmation(false);
            if (success) {
              push(`/client/${client?.tenant}/admin/produtos`);
            }
          }}
          tenant={client?.tenant || ''}
          productId={productId}
          productName={name}
        />
      )}
      <h1>{newProduct ? t('productPage:addNewProduct', { defaultValue: 'Adicionar Novo Produto' }) :
        t('productPage:editProductName', { productName: name, defaultValue: `Editar ${name}` })}
      </h1>

      <div className={style.mainInputs}>
        <label className={style.productIcon} htmlFor='image'>
          {(imagePath !== undefined &&
            imagePath !== '' &&
            imagePath !== null) ||
            image ? (
            <img
              width={80}
              height={80}
              src={image ? window.URL.createObjectURL(image) : imagePath}
              alt={name}
            />
          ) : (
            <img width={80} height={80} src='/icone_produto.png' />
          )}
          <input
            type='file'
            name=''
            accept='image/*'
            id='image'
            onChange={handleImageChange}
          />
          <FiPlus className={style.addImageButton} />
        </label>
        <div className={style.name}>
          <Input label={t('productPage:name', { defaultValue: 'Nome' })} onChange={setName} value={name} required />
        </div>
        <div className={style.codeText}>
          <Input
            disabled={!newProduct}
            required
            label={t('productPage:code', { defaultValue: 'Código' })}
            onChange={(value) => setCodeText(value)}
            value={codeText}
          />
        </div>
        <div className={style.barCode}>
          <Input
            label={t('productPage:barCode', { defaultValue: 'Código de Barras' })}
            value={barCode}
            onChange={setBarCode}
            required
          />
        </div>
      </div>
      <div className={style.formVerticalGroup}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            columnGap: '2rem',
            rowGap: '1rem',
          }}
        >
          <Input label={t('productPage:type', { defaultValue: 'Tipo' })} value={type} onChange={setType} required />
          <Input label={t('productPage:brand', { defaultValue: 'Marca' })} value={brand} onChange={setBrand} required />
          <Input
            label={t('productPage:packSize', { defaultValue: 'Unidades por Fardo' })}
            type='number'
            min={1}
            step={1}
            required
            value={packSize}
            onChange={(value) => setPackSize(parseInt(value))}
          />
          <Select
            disabled={!newProduct}
            options={AvailableUnits.map(({ text, code }) => t(`productPage:${code}`, { defaultValue: text }))}
            label={t('productPage:measurementUnit', { defaultValue: 'Unidade de medida de massa' })}
            noEmpty
            value={unit?.text ?? ''}
            onChange={(value) => {
              const foundUnit = AvailableUnits.find(
                ({ text }) => text === value
              );
              if (foundUnit) {
                setUnit({
                  unitName: foundUnit.unitName,
                  resolution: foundUnit.resolution,
                  text: foundUnit.text,
                });
              }
            }}
            id='unitSelector'
            required
          />
          <Input
            label={t('productPage:netWeightUnitName', { unitName: unit?.unitName, defaultValue: `Peso Líquido (${unit?.unitName ?? ''})` })}
            type='number'
            min={0}
            step={1 / Math.pow(10, unit?.resolution ?? 0)}
            value={netWeight}
            onChange={(value) => setNetWeight(parseFloat(value))}
            required
          />
          <Input
            label={t('productPage:packageWeightUnitName', { unitName: unit?.unitName, defaultValue: `Peso da Embalagem (${unit?.unitName ?? ''})` })}
            type='number'
            min={0}
            step={1 / Math.pow(10, unit?.resolution ?? 0)}
            value={packageWeight}
            required
            onChange={(value) => setPackageWeight(parseFloat(value))}
          />
          <Input
            label={t('productPage:correctionBelowUnitName', { unitName: unit?.unitName, defaultValue: `Correção de Peso Abaixo de (${unit?.unitName ?? ''})` })}
            type='number'
            min={1}
            step={1 / Math.pow(10, unit?.resolution ?? 0)}
            value={correctionBelow}
            required
            onChange={(value) => setCorrectionBelow(parseFloat(value))}
          />
          <Input
            label={t('productPage:correctionAboveUnitName', { unitName: unit?.unitName, defaultValue: `Correção de Peso Acima de (${unit?.unitName ?? ''})` })}
            type='number'
            min={1}
            step={1 / Math.pow(10, unit?.resolution ?? 0)}
            value={correctionAbove}
            required
            onChange={(value) => setCorrectionAbove(parseFloat(value))}
          />
          <Input
            label={t('productPage:rejectionUnderUnitName', { unitName: unit?.unitName, defaultValue: `Rejeição Abaixo de (${unit?.unitName ?? ''})` })}
            type='number'
            min={1}
            step={1 / Math.pow(10, unit?.resolution ?? 0)}
            value={rejectUnder}
            required
            onChange={(value) => setRejectUnder(parseFloat(value))}
          />
          <Input
            label={t('productPage:rejectionAboveUnitName', { unitName: unit?.unitName, defaultValue: `Rejeição Acima de (${unit?.unitName ?? ''})` })}
            type='number'
            min={1}
            step={1 / Math.pow(10, unit?.resolution ?? 0)}
            value={rejectOver}
            required
            onChange={(value) => setRejectOver(parseFloat(value))}
          />
          <Checkbox
            label={t('productPage:useFactoryDefault', { defaultValue: 'Usar padrões de fábrica para os inválidos' })}
            checked={useFactoryDefault}
            onChange={setUseFactoryDefault}
            labelStyle={{ whiteSpace: 'break-spaces' }}
          />
          <Input
            disabled={useFactoryDefault}
            label={t('productPage:invalidWeightWhenDevianceAboveUnitName', { unitName: unit?.unitName, defaultValue: `Inválidos quando desvio acima de (${unit?.unitName})` })}
            type='number'
            min={1}
            step={1 / Math.pow(10, unit?.resolution ?? 0)}
            value={invalidWeightWhenDevianceAbove}
            required
            onChange={(value) =>
              setInvalidWeightWhenDevianceAbove(parseFloat(value))
            }
          />
          <Input
            disabled={useFactoryDefault}
            label={t('productPage:invalidWeightBelowUnitName', { unitName: unit?.unitName, defaultValue: `Inválidos abaixo de (${unit?.unitName})` })}
            type='number'
            min={1}
            step={1 / Math.pow(10, unit?.resolution ?? 0)}
            value={invalidWeightBelow}
            required
            onChange={(value) => setInvalidWeightBelow(parseFloat(value))}
          />
          <Input
            disabled={useFactoryDefault}
            label={t('productPage:invalidWeightAboveUnitName', { unitName: unit?.unitName, defaultValue: `Inválidos acima de (${unit?.unitName})` })}
            type='number'
            min={1}
            step={1 / Math.pow(10, unit?.resolution ?? 0)}
            value={invalidWeightAbove}
            required
            onChange={(value) => setInvalidWeightAbove(parseFloat(value))}
          />
        </div>
      </div>
      <div className={style.buttons}>
        <Button
          secondary
          danger
          onClick={() => push(`/client/${client?.tenant}/admin/produtos`)}
        >
          {t('productPage:cancel', { defaultValue: 'Cancelar' })}
        </Button>
        {!newProduct && (
          <>
            <Button danger onClick={() => setWaitDeleteConfirmation(true)}>
              {t('productPage:delete', { defaultValue: 'Excluir' })}
            </Button>
          </>
        )}
        <Button type='submit'>{!newProduct ? t('productPage:save', { defaultValue: 'Salvar' }) : t('productPage:add', { defaultValue: 'Adicionar' })}</Button>
      </div>
    </form>
  );
};
