import convert from 'convert-units';
import { formatNumberToNotation } from '../../../../../helper';
import { useMemo } from 'react';
import { ProductionClassificationSerie } from './intefaces/ProductionClassificationSerie';
import uniqueStyle from './ProductionClassificationPage.module.css';
import { useTranslation } from 'react-i18next';

const normalizedUnit = (unitString: string) => {
  switch (unitString) {
    case 'g':
    case 'kg':
    case 'lb':
      return unitString;
    default:
      return 'g';
  }
};

export const ProductionClassificationTable = ({
  series,
}: {
  series: ProductionClassificationSerie[];
}): React.ReactElement => {
  const { t } = useTranslation();
  const labels: any = {
    ['Pesagens úteis']: t('specificChartPage:usefulWeighings', { defaultValue: 'Pesagens úteis' }),
    ['Pesagens inválidas']: t('specificChartPage:invalidWeighings', { defaultValue: 'Pesagens inválidas' }),
    ['Pesagens rejeitadas por peso superior']: t('specificChartPage:superiorRejectedWeighings', { defaultValue: 'Pesagens rejeitadas por peso superior' }),
    ['Pesagens rejeitadas por peso inferior']: t('specificChartPage:inferiorRejectedWeighings', { defaultValue: 'Pesagens rejeitadas por peso inferior' }),
    ['Pesagens úteis fora da conformidade']: t('specificChartPage:usefulWeighingsOutCompliance', { defaultValue: 'Pesagens úteis fora da conformidade' }),
    ['Pesagens inspecionadas']: t('specificChartPage:inspectedWeighings', { defaultValue: 'Pesagens inspecionadas' }),
    ['Pesagens úteis na faixa de correção do dosador']: t('specificChartPage:weighingsInRange', { defaultValue: 'Pesagens úteis na faixa de correção do dosador' }),
    ['Pesagens úteis abaixo da correção do dosador']: t('specificChartPage:weighingsBelowRange', { defaultValue: 'Pesagens úteis abaixo da correção do dosador' }),
    ['Pesagens úteis acima da correção do dosador']: t('specificChartPage:weighingsAboveRange', { defaultValue: 'Pesagens úteis acima da correção do dosador' }),
    ['Pesagens não inspecionadas']: t('specificChartPage:uninspectedWeighings', { defaultValue: 'Pesagens não inspecionadas' }),
  }

  const codes: any = {
    'a': 'Pesagens úteis',
    'ar': 'Pesagens úteis na faixa de correção do dosador',
    'aru': 'Pesagens úteis abaixo da correção do dosador',
    'aro': 'Pesagens úteis acima da correção do dosador',
    'pi': 'Pesagens inválidas',
    'ro': 'Pesagens rejeitadas por peso superior',
    'ru': 'Pesagens rejeitadas por peso inferior',
    'rc': 'Pesagens úteis fora da conformidade',
    'ni': 'Pesagens não inspecionadas',
    'i': 'Pesagens inspecionadas',
  };

  console.log('series', series);
  const tableData = useMemo(() => {
    const classifications = Array.from(
      new Set(series.map(({ data }) => data.map(({ x }) => x)).flat())
    );
    const data = series.map(({ name, data }) => {
      let total = 0;
      classifications.forEach((classification) => {
        const found = data.find(({ x }) => x === classification);
        if (found?.x === 'a' || found?.x === 'pi' || found?.x === 'ro' || found?.x === 'ru' || found?.x === 'rc' || found?.x === 'i' || found?.x === 'ni' || found?.x === 'ar' || found?.x === 'aru' || found?.x === 'aro') {
          total += found?.y;
        }
      });
      const columns = classifications.map((classification) => {
        const found = data.find(({ x }) => x === classification);

        return {
          classification,
          un: found?.y ?? 0,
          sum: found?.metadata?.sum ?? 0,
          dev: found?.metadata?.dev ?? 0,
          unit: found?.metadata?.weightUnit ?? '',
          avg: name === 'Total' ? (found?.metadata?.sum ?? 0) / (found?.y ?? 1) : found?.metadata?.avg ?? 0,
          total: total
        };
      });

      return { row: name, columns };
    });
    return data;
  }, [series]);

  const calcColumnPercentage = (columns: any) => {
    let parcialValue: number = 0;
    columns.forEach((col: any) => {
      if (isUsedColumn(col)) {
        parcialValue += col.un;
      }
    });
    let totalValue: number = 0;
    tableData.find(td => td.row === 'Total')?.columns.forEach((col: any) => {
      if (isUsedColumn(col)) {
        totalValue += col.un;
      }
    });
    return formatNumberToNotation(parcialValue / totalValue * 100);
  }

  const isUsedColumn = (col: any) => {
    return col.classification === 'a' ||
      col.classification === 'i' ||
      col.classification === 'ro' ||
      col.classification === 'ru' ||
      col.classification === 'pi' ||
      col.classification === 'rc' ||
      col.classification === 'ni' ||
      col.classification === 'ar' ||
      col.classification === 'aru' ||
      col.classification === 'aro';
  }


  if (!tableData || tableData.length === 0) {
    return <div />;
  }
  console.log('tableData', tableData);
  return (
    <div className={uniqueStyle.tableContainer}>
      <table>
        <tr>
          <th rowSpan={2}>{t('specificChartPage:machine', { defaultValue: 'Máquina' })}</th>
          {tableData[0].columns.map(({ classification }) => (
            <td className={uniqueStyle.classificationTitle} colSpan={classification === 'a' ? 4 : 3}>
              {labels[codes[classification]] ?? classification}
            </td>
          ))}
        </tr>
        <tr>
          {tableData[0].columns
            .map(({ classification }) => [
              <td className={uniqueStyle.classificationTitle}>{t('specificChartPage:quantity', { defaultValue: 'Quantidade' })}</td>,
              <td className={uniqueStyle.classificationTitle}>
                {t('specificChartPage:accumulatedWeight', { defaultValue: 'Peso Acumulado' })}
              </td>,
              <td className={uniqueStyle.classificationTitle}>{t('specificChartPage:averageWeight', { defaultValue: 'Peso Médio' })}</td>,
              (classification === 'a' ?
                <td className={uniqueStyle.classificationTitle}>{t('specificChartPage:standardDeviationTable', { defaultValue: 'Desvio Padrão' })}</td>
                : null)
            ])
            .flat()}
        </tr>
        {tableData.map(({ row, columns }, i) => (
          <tr>
            <th style={{ whiteSpace: 'nowrap' }}>{row} {row !== 'Total' ? `(${calcColumnPercentage(columns)}%)` : ''}</th>
            {columns
              .map(({ classification, sum, un, unit, avg, total, dev }) => {
                let converted = convert(
                  convert(sum).from('g').to(normalizedUnit(unit))
                )
                  .from(normalizedUnit(unit))
                  .toBest({ exclude: ['mt', 'mg', 'mcg'] });
                let convertedDev = convert(
                  convert(dev).from('g').to(normalizedUnit(unit))
                )
                  .from(normalizedUnit(unit))
                  .toBest({ exclude: ['mt', 'mg', 'mcg'] });
                let convertedAvg = convert(
                  convert(avg).from('g').to(normalizedUnit(unit))
                )
                  .from(normalizedUnit(unit))
                  .toBest({ exclude: ['mt', 'mg', 'mcg'] });
                if (convertedAvg.val < 10000) {
                  if (convertedAvg.unit === 'kg') {
                    convertedAvg = {
                      val: convertedAvg.val * 1000,
                      unit: 'g',
                      singular: 'g',
                      plural: 'g',
                    };
                  }
                  if (convertedAvg.unit === 't') {
                    convertedAvg = {
                      val: convertedAvg.val / 1000,
                      unit: 'kg',
                      singular: 'kg',
                      plural: 'kg',
                    };
                  }
                }
                if (converted.val > 1000) {
                  if (converted.unit === 'kg') {
                    converted = {
                      val: converted.val / 1000,
                      unit: 't',
                      singular: 't',
                      plural: 't',
                    };
                  } else if (converted.unit === 'g') {
                    converted = {
                      val: converted.val / 1000,
                      unit: 'kg',
                      singular: 'kg',
                      plural: 'kg',
                    };
                  }
                }
                if (convertedDev.val < 10000) {
                  if (convertedDev.unit === 'kg') {
                    convertedDev = {
                      val: convertedDev.val * 1000,
                      unit: 'g',
                      singular: 'g',
                      plural: 'g',
                    };
                  }
                  if (convertedDev.unit === 't') {
                    convertedDev = {
                      val: convertedDev.val / 1000,
                      unit: 'kg',
                      singular: 'kg',
                      plural: 'kg',
                    };
                  }
                }
                return [
                  <td>{formatNumberToNotation(un)} ({formatNumberToNotation(un / total * 100)} %)</td>,
                  <td>{`${formatNumberToNotation(converted.val)} ${converted.unit
                    }`}</td>,
                  <td>{`${formatNumberToNotation(convertedAvg.val)} ${convertedAvg.unit
                    }`}</td>,
                    (classification === 'a' ? 
                  <td>{row === "Total" ? "-" :`${formatNumberToNotation(convertedDev.val)} ${convertedDev.unit}`}</td>
                  : null)
                ];
              })
              .flat()}
          </tr>
        ))}
      </table>
    </div>
  );
};
