import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useData } from '../Controls/DataControl/UseData';
import { FilterInterval, useFilter } from '../Controls/FilterControl';
import style from './SpecificChartPage.module.css';
import Chart from 'react-apexcharts';
import { Production, Event } from '../Controls/DataControl/models';
import { ApexOptions } from 'apexcharts';
import { addHours, format } from 'date-fns';
import { computeInterval } from '../Controls/FilterControl/utils';
import { EmptyState } from '../Components/Cards/EmptyState';
import { formatSecondsToHumanReadableTime } from '../../../../helper/FormatSecondsToHumanReadableTime';
import { LoadingState } from '../Components/Cards/LoadingState';
import { ChartOptionsLegend } from '../Components/Cards/ChartOptionsLegend';
import { Filter } from '../Controls/FilterControl/models';
import convert from 'convert-units';
import { SpecificChartPageTitle } from '../Components/SpecificChartPageTitle';
import { ChartOptionsDatetimeXaxis } from '../Components/Cards/ChartOptionsDatetimeXaxis';
import { AxisTitleConfiguration } from '../../../../helper/AxisTitleConfiguration';
import { formatNumberToNotation } from '../../../../helper/FormatNumberToNotation';
import { useLocale } from '../../../LocaleControl';
import { useTranslation } from 'react-i18next';
import { getDateFnsLocation } from '../../../../helper/GetDateFnsLocation';
import { WeightOverTimeFilterModal } from '../Components/WeightOverTimeFilterModal';
import { getWeightOverTimeBlacklist } from '../Components/WeightOverTimeFilterModal/WeightOverTimeFilterService';

const normalizedUnit = (unitString: string) => {
  switch (unitString) {
    case 'g':
    case 'kg':
    case 'lb':
      return unitString;
    default:
      return 'g';
  }
};
export const ProductionOverTimePage = ({
  containerHeight,
}: {
  containerWidth: number;
  containerHeight: number;
}): React.ReactElement => {
  const { fetchProduction, fetchEvents } = useData();
  const { filter, resetFilterRules } = useFilter();
  const [production, setProduction] = useState<Production[]>();
  const [events, setEvents] = useState<Event[]>();
  const [options, setOptions] = useState<ApexOptions>();
  const [series, setSeries] = useState<any[]>();
  const [loading, setLoading] = useState(true);
  const localInterval = useRef<FilterInterval>(filter.interval);
  const chartHeight = useMemo(() => containerHeight - 430, [containerHeight]);
  const isEmpty = useMemo(() => !!(series && series?.length === 0), [series]);
  const abortFetchRef = useRef<() => void>();
  const abortEventsFetchRef = useRef<() => void>();
  const { chartOptionsLocale } = useLocale();
  const { t } = useTranslation();
  const FALLBACK_LANGUAGE = 'pt_BR';
  const [showFilter, setShowFilter] = useState(false);
  const [weightOverTimeBlacklist, setweightOverTimeBlacklist] = useState<string[]>(getWeightOverTimeBlacklist());
  const [brushOptions, setBrushOptions] = useState<ApexOptions>();

  const localFetch = useCallback(
    async (localFetchFilter: Filter, weightOverTimeBlacklist: string[]) => {
      setLoading(true);
      setProduction(undefined);
      setEvents(undefined);
      if (abortFetchRef.current) {
        abortFetchRef.current();
        abortFetchRef.current = undefined;
      }
      if (abortEventsFetchRef.current) {
        abortEventsFetchRef.current();
        abortEventsFetchRef.current = undefined;
      }
      localInterval.current = '5m';
      const { abort, fetch } = fetchProduction(weightOverTimeBlacklist);
      const { abort: eventsAbort, fetch: eventsFetch } = fetchEvents();
      abortFetchRef.current = abort;
      abortEventsFetchRef.current = eventsAbort;
      try {
        const result = await fetch(localFetchFilter);
        const eventsResult = await eventsFetch(localFetchFilter);
        setProduction(result);
        setEvents(eventsResult);
      } catch (err) {
        console.error(err);
      }
    },
    [abortFetchRef, fetchProduction, setProduction]
  );

  useEffect(() => {
    resetFilterRules();
  }, []);

  useEffect(() => {
    return () => {
      setSeries(undefined);
      setProduction(undefined);
      setEvents(undefined);
      setOptions(undefined);
    };
  }, [setEvents, setSeries, setProduction, setOptions]);

  useEffect(() => {
    setProduction(undefined);
    localFetch(filter, weightOverTimeBlacklist);
  }, [filter, fetchProduction, weightOverTimeBlacklist]);


  const chartOptionsSection: Pick<ApexOptions, 'chart'> = useMemo(
    () => ({
      chart: {
        type: 'line',
        animations: {
          enabled: false,
        },
        ...chartOptionsLocale,
        zoom: {
          enabled: false,
          type: 'x',
          autoScaleYaxis: false,
        },
        id: 'weightOverTime',
        toolbar: { show: true, autoSelected: 'pan', offsetY: -32 },
      },
    }),
    [
      chartOptionsLocale,
      localInterval.current,
    ]
  );

  const responsiveOptionsSection: Pick<ApexOptions, 'responsive'> = useMemo(
    () => ({
      responsive: [
        {
          breakpoint: 900,
          options: { xaxis: { tickAmount: 3 } },
        },
        {
          breakpoint: 1400,
          options: { xaxis: { tickAmount: 7 } },
        },
      ],
    }),
    []
  );

  const tooltipOptionsSectionIndividual: Pick<ApexOptions, 'tooltip'> = useMemo(
    () => ({
      tooltip: {
        shared: false,
        custom: ({ seriesIndex, dataPointIndex, w }) => {
          const dataPoint =
            w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          const serieName = w.globals.initialSeries[seriesIndex].name;
          const sumVal = convert(dataPoint.y)
            .from('g')
            .to(normalizedUnit(dataPoint.metadata.unit));
          return `<div >
          <div style="padding: 2px 8px;">${`${format(
            new Date(dataPoint.x),
            'cccccc dd MMM yy HH:mm:ss',
            {
              locale: getDateFnsLocation(localStorage.getItem('lng') || FALLBACK_LANGUAGE),
            }
          )}`}</div>
          <div style="padding: 2px 8px; border-bottom: 1px solid #ddd">${serieName}</div>
          <div style=" padding: 2px 8px">

          <div style="display: flex;  gap:2rem; justify-content: space-between;"><span>${t('specificChartPage:value', { defaultValue: 'Valor' })}</span><span>${formatNumberToNotation(
            sumVal
          )} ${dataPoint.metadata.unit}</span></div>
          </div>`;
        },
      },
    }),
    [formatSecondsToHumanReadableTime]
  );

  const tooltipOptionsSectionGrouped: Pick<ApexOptions, 'tooltip'> = useMemo(
    () => ({
      tooltip: {
        shared: false,
        custom: ({ seriesIndex, dataPointIndex, w }) => {
          const dataPoint =
            w.globals.initialSeries[seriesIndex].data[dataPointIndex];

          const serieName = w.globals.initialSeries[seriesIndex].name;
          const sumVal = convert(dataPoint.metadata?.sum ?? 0)
            .from('g')
            .to(normalizedUnit(dataPoint.metadata?.unit));

          const avgVal = convert(dataPoint.metadata?.avg ?? 0)
            .from('g')
            .to(normalizedUnit(dataPoint.metadata?.unit));

          const maxVal = convert(dataPoint.metadata?.max ?? 0)
            .from('g')
            .to(normalizedUnit(dataPoint.metadata?.unit));

          const minVal = convert(dataPoint.metadata?.min ?? 0)
            .from('g')
            .to(normalizedUnit(dataPoint.metadata?.unit));

          const devVal = convert(dataPoint.metadata?.dev ?? 0)
            .from('g')
            .to(normalizedUnit(dataPoint.metadata?.unit));

          return `<div >
          <div style="padding: 2px 8px;">${`${format(
            new Date(dataPoint.x),
            'cccccc dd MMM yy HH:mm:ss',
            {
              locale: getDateFnsLocation(localStorage.getItem('lng') || FALLBACK_LANGUAGE),
            }
          )}`}</div>
          <div style="padding: 2px 8px; border-bottom: 1px solid #ddd">${serieName}</div>
          <div style=" padding: 2px 8px">
          <div style="display: flex; gap:2rem; justify-content: space-between;"><span>${t('specificChartPage:averageInt', { defaultValue: 'Int. médio' })}</span> <span>${formatSecondsToHumanReadableTime(
            dataPoint.metadata.interval / 1000,
            true
          )}</span></div>
          <div style="display: flex; gap:2rem; justify-content: space-between;"><span>${t('specificChartPage:average', { defaultValue: 'Média' })}</span> <span>${formatNumberToNotation(
            avgVal
          )} ${dataPoint.metadata.unit}</span></div>
          <div style="display: flex;  gap:2rem; justify-content: space-between;"><span>${t('specificChartPage:max', { defaultValue: 'Máx.' })}</span><span>${formatNumberToNotation(
            maxVal
          )} ${dataPoint.metadata.unit}</span></div>
          <div style="display: flex;  gap:2rem; justify-content: space-between;"><span>${t('specificChartPage:min', { defaultValue: 'Mín.' })}</span><span>${formatNumberToNotation(
            minVal
          )} ${dataPoint.metadata.unit}</span></div>
          <div style="display: flex;  gap:2rem; justify-content: space-between;"><span>${t('specificChartPage:standardDeviation', { defaultValue: 'Desvio p.' })}</span><span>${formatNumberToNotation(
            devVal
          )} ${dataPoint.metadata.unit}</span></div>
          <div style="display: flex;  gap:2rem; justify-content: space-between;"><span>${t('specificChartPage:quant', { defaultValue: 'Quant.' })}</span><span>${formatNumberToNotation(
            parseInt(dataPoint.metadata.count ?? 1)
          )} un</span></div>
          <div style="display: flex;  gap:2rem; justify-content: space-between;"><span>${t('specificChartPage:sum', { defaultValue: 'Somatório' })}</span><span>${formatNumberToNotation(
            sumVal
          )} ${dataPoint.metadata.unit}</span></div>
          <div>
          </div>`;
        },
      },
    }),
    [formatSecondsToHumanReadableTime]
  );

  const tooltipOptionsSection: Pick<ApexOptions, 'tooltip'> =
    localInterval.current === '5m'
      ? tooltipOptionsSectionGrouped
      : tooltipOptionsSectionIndividual;

  useEffect(() => {
    setOptions({
      noData: {
        text: t('specificChartPage:noDataForTheSelectedPeriod', { defaultValue: 'Não há dados para o período selecionado.' }),
      },
      markers: {
        showNullDataPoints: false,
        size: 4,
      },
      stroke: {
        curve: 'straight',
        width: localInterval.current === '5m' ? 4 : 0,
      },
      ...responsiveOptionsSection,
      ...chartOptionsSection,
      ...ChartOptionsDatetimeXaxis(7),
      ...tooltipOptionsSection,
      ...ChartOptionsLegend,
      yaxis: {
        title: AxisTitleConfiguration(t('specificChartPage:weight', { defaultValue: 'Peso' })),
        forceNiceScale: true,
        decimalsInFloat: 0,
        labels: {
          minWidth: 50,
          formatter: formatNumberToNotation,
          style: { fontSize: '1em' },
        },
      },
      fill: {
        opacity: 1,
      },
      dataLabels: {
        enabled: false,
      },
    });
  }, [
    responsiveOptionsSection,
    chartOptionsSection,
    tooltipOptionsSection,
    localInterval,
    ChartOptionsLegend,
  ]);

  useEffect(() => {
    if (!production || !events) {
      return;
    }
    const uninformed = t('specificChartPage:uninformed', { defaultValue: 'Não informado' });
    let newSeries = production.map(
      ({ product, equipment, entries, unit, ...rest }) => {
        const existingEvents = events.find(
          ({ equipment: equipmentEvent }) => equipment.id === equipmentEvent.id
        );
        return {
          name: `${equipment.name} (${product?.code ?? uninformed} - ${product.name ?? uninformed
            })`,
          data: [
            ...entries.map(({ avg, sum, date, count, ...restEntry }) => ({
              x: date.getTime(),
              y: convert(avg ?? sum)
                .from('g')
                .to(normalizedUnit(unit)),
              metadata: {
                ...rest,
                ...restEntry,
                avg: sum / count,
                sum,
                count,
                interval:
                  localInterval.current === '5m' ? 1000 * 60 * 5 : 1000 * 60,
                equipmentName: equipment.name,
                equipmentId: equipment.id,
                unit,
                product,
              },
            })),
            ...(existingEvents?.events.map(({ windowStart }) => ({
              x: windowStart.getTime(),
              y: null,
              metadata: {
                unit,
                count: 0,
                interval:
                  localInterval.current === '5m' ? 1000 * 60 * 5 : 1000 * 60,
              },
            })) ?? []),
          ].sort((a, b) => a.x - b.x),
        };
      }
    );
    setSeries(newSeries);
    setLoading(false);
  }, [production, events, localInterval.current, setLoading]);

  useEffect(() => {
    const firstDate =
      production && production.length
        ? production
          .map(({ entries }) => entries)
          .flat()
          .sort((a, b) => a.date.getTime() - b.date.getTime())[0]
          .date.getTime()
        : filter.dates.length > 0 ? filter.dates[0].startTimestamp : undefined;

    setBrushOptions({
      noData: {
        text: t('specificChartPage:noDataForTheSelectedPeriod', { defaultValue: 'Não há dados para o período selecionado.' }),
      },
      markers: {
        showNullDataPoints: false,
        size: 0,
      },
      stroke: {
        curve: 'straight',
      },
      ...responsiveOptionsSection,
      chart: {
        type: 'line',
        animations: {
          enabled: false,
        },
        brush: {
          autoScaleYaxis: false,
          target: 'weightOverTime',
          enabled: true,
        },
        selection: {
          enabled: true,
          xaxis: {
            min: firstDate,
            max: addHours(
              firstDate ?? (filter.dates.length > 0 ? filter.dates[0].startTimestamp : new Date()),
              1
            ).getTime(),
          },
        },
        id: 'brushChart',
        toolbar: { show: true },
      },
      ...ChartOptionsDatetimeXaxis(7),
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        markers: { radius: 6, width: 12, height: 12 },
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        show: false,
        onItemClick: {
          toggleDataSeries: false,
        },
      },
      yaxis: {
        forceNiceScale: true,
        decimalsInFloat: 0,
        labels: {
          minWidth: 50,
          formatter: formatNumberToNotation,
          style: { fontSize: '1em' },
        },
      },
      fill: {
        opacity: 1,
      },
      dataLabels: {
        enabled: false,
      },
    })
  }, [production, filter.dates]);

  return (
    <>{showFilter && (
      <WeightOverTimeFilterModal
        handleClose={() => {
          setweightOverTimeBlacklist(getWeightOverTimeBlacklist());
          setShowFilter(false);
        }}
      />
    )}
      <div className={style.container}>
        <SpecificChartPageTitle text={t('specificChartPage:weightXTime', { defaultValue: 'Peso x Tempo' })}
          onFilterClick={() => {
            setShowFilter(true);
          }} />
        {loading && <LoadingState />}
        {!loading &&
          (isEmpty ? (
            <EmptyState />
          ) : (
            <>
              <Chart options={options} series={series} height={chartHeight} />
              <Chart options={brushOptions} series={series} height={220} />
            </>
          ))}
      </div>
    </>
  );
};
