export const saveWeightOverTimeBlacklist = (weightOverTimeCodes: string[]) => {
  localStorage.setItem(
    '@medianeira:filter:weightxtime',
    JSON.stringify(weightOverTimeCodes)
  );
};

export const getWeightOverTimeBlacklist = (): string[] => {
  const data = localStorage.getItem('@medianeira:filter:weightxtime');
  if (!data) return [];
  return JSON.parse(data);
};
