import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { Button } from '../../../Components/Atoms/Button';
import { Input } from '../../../Components/Atoms/Input';
import * as yup from 'yup';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../AuthControl';
import AppError from '../../../helper/AppError';
import style from './ResetPassword.module.css';
import { useTenant } from '../../TenantControl';
import { useTranslation } from 'react-i18next';

export const ResetPassword = (): React.ReactElement => {
  const { addToast } = useToast();
  const { search } = useLocation();
  const { client } = useTenant();
  const [errors, setErrors] = useState<{ [key: string]: string[] }>();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { api } = useAuth();
  const history = useHistory();
  const { t } = useTranslation();
  const query = new URLSearchParams(search);
  const token = query.get('token');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const schema = yup.object().shape({
      email: yup.string().email().required(t('userConfigTenantPage:emailIsMandatory', { defaultValue: 'Email é obrigatório' })),
      password: yup.string().required(t('userConfigTenantPage:passwordIsMandatory', { defaultValue: 'Senha é obrigatório' })),
      confirmPassword: yup
        .string()
        .required(t('userConfigTenantPage:passwordConfirmationIsMandatory', { defaultValue: 'Confirmação de senha é obrigatório' }))
        .oneOf([yup.ref('password')], t('userConfigTenantPage:passwordsDontMatch', { defaultValue: 'Senhas não conferem' })),
    });
    try {
      const body = {
        token,
        email,
        password,
        confirmPassword,
      };
      await schema.validate(body, { abortEarly: false });
      await api.post(`/api/client/${client?.tenant}/users/access/reset`, body);
      history.push(`/client/${client?.tenant}/login`);
      addToast({
        type: 'success',
        description: t('userConfigTenantPage:passwordResetSuccessfully', { defaultValue: 'Senha definida com sucesso.' }),
        title: t('userConfigTenantPage:success', { defaultValue: 'Sucesso' }),
      });
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        setErrors(
          error.inner.reduce((acc, curr) => {
            if (curr.path) {
              return { ...acc, [curr.path]: curr.errors };
            } else return acc;
          }, {})
        );
      } else if (error instanceof AppError) {
        if (error.statusCode === 400) {
          addToast({
            type: 'error',
            description: t('userConfigTenantPage:invalidData', { defaultValue: 'Dados inválidos' }),
            title: 'Erro',
          });
        } else {
          addToast({
            type: 'error',
            description: t('userConfigTenantPage:unknownError', { defaultValue: 'Erro desconhecido' }),
            title: 'Erro',
          });
        }
      }
    }
  };
  return (
    <div className={style.container}>
      <section className={style.background}>
        <img src='/background.png' />
      </section>
      <section className={style.body}>
        <div>
          <img
            src={
              client?.logoPath ? client?.logoPath : '/logo_cliente_supervis.png'
            }
          />
          <h1>{t('userConfigTenantPage:resetYourPassword', { defaultValue: 'Redefina sua senha' })}</h1>
          <form onSubmit={handleSubmit}>
            <Input
              id='email'
              grow
              label='Login'
              type='email'
              value={email}
              onChange={setEmail}
              errors={errors && errors['email']}
            />
            <Input
              id='password'
              grow
              label={t('userConfigTenantPage:password', { defaultValue: 'Senha' })}
              type='password'
              value={password}
              onChange={setPassword}
              errors={errors && errors['password']}
            />
            <Input
              id='confirm-password'
              grow
              label={t('userConfigTenantPage:confirmPassword', { defaultValue: 'Confirme a Senha' })}
              type='password'
              value={confirmPassword}
              onChange={setConfirmPassword}
              errors={errors && errors['confirmPassword']}
            />
            <div className='flex justify-center w-full'>
              <Button type='submit'>{t('userConfigTenantPage:resetPassword', { defaultValue: 'Redefinir' })}</Button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};
